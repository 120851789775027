import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {cerratoTheme} from "./theme/cerratoTheme";
import Services from "./pages/Services";
import {Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import {AuthProvider} from "./services/AuthContext";
import {useState} from "react";
import OreCommessa from "./pages/OreCommessa";

function App() {
    const [currentUser, setCurrentUser] = useState(null)

    return (
        <ThemeProvider theme={cerratoTheme}>
            <CssBaseline/>

            <AuthProvider value={{currentUser}}>

                <Routes>
                    <Route path={'/accedi'} element={<Login/>}/>
                    <Route element={<ProtectedRoute/>}>
                        <Route path={'/'} element={<Services/>}/>
                        <Route path={'/ore-commessa'} element={<OreCommessa/>}/>
                    </Route>
                </Routes>

            </AuthProvider>

        </ThemeProvider>
    );
}

export default App;
