import React, {useState} from "react";
import {useNavigate} from 'react-router-dom'
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CircularProgress,
    Container,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { useAuth } from "../services/AuthContext"

const Login = () => {
    let navigate = useNavigate()
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [cognome, setCognome] = useState(null)
    const [cognomi, setCognomi] = useState(['Carbune'])

    const {signIn} = useAuth();

    const handleLogin = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);

        if (signIn(cognome)) {
            navigate("/");
        } else {
            setLoading(false);
        }
    }

    return (
        <main>
            <Container sx={{textAlign: 'center', paddingTop: '2rem'}}>
                <Card style={{height: '100%', textAlign: 'right'}} variant={'login'}>
                    <Stack direction={"column"} alignItems={"center"} textAlign={'center'}>

                        <Typography sx={{mb: 4}} variant={'h4'}>Cerrato - Accesso Dipendenti</Typography>

                        <Autocomplete
                            id="cognome-select"
                            sx={{ width: 300 }}
                            options={cognomi}
                            autoHighlight
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => setCognome(value)}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Scegli il tuo Cognome dall'elenco"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />

                        <Button onClick={handleLogin} variant={"contained"} color={"primary"} sx={{mt: 5, px: 5}}
                                disabled={!cognome}
                                style={{height: '3rem', fontSize: '1.2rem'}}>Accedi</Button>

                    </Stack>
                </Card>
            </Container>
        </main>
    )
}

export default Login
