import React, {useContext, useState} from 'react'

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null)

    function signIn(cognome) {
        setCurrentUser({
            cognome: cognome
        });
        return true;
    }

    function getUser() {
        return currentUser;
    }

    const value = {
        currentUser,
        signIn,
        getUser
    }

    return (
        <AuthContext.Provider value={value}>
            { children }
        </AuthContext.Provider>
    )

}
