import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {useAuth} from "../services/AuthContext";

const ProtectedRoute = ({children }) => {

    const {currentUser} = useAuth();

    if (!currentUser) {
        return <Navigate to="/accedi" replace />;
    }

    return children ? children : <Outlet/>;
};

export default ProtectedRoute
