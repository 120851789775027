import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Container,
    Grid,
    InputAdornment,
    Snackbar,
    Stack,
    TextField
} from "@mui/material";
import MenuAppBar from "../components/MenuAppBar";
import {useAuth} from "../services/AuthContext";
import {Save} from "@mui/icons-material";
import {useState} from "react";

const OreCommessa = () => {

    const {currentUser} = useAuth();

    const [commessa, setCommessa] = useState();
    const [attivita, setAttivita] = useState();
    const [ore, setOre] = useState();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <main>
            <MenuAppBar user={currentUser}/>
            <Container>
                <Grid container spacing={2} sx={{mt: 4}}>
                    <Grid item xs={12} md={6} lg={4}>

                        <Autocomplete
                            id="commessa-select"
                            sx={{ width: 300 }}
                            options={['W01656']}
                            autoHighlight
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => setCommessa(value)}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Scegli la commessa dall'elenco"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>

                        <Autocomplete
                            id="attivita-select"
                            sx={{ width: 300 }}
                            options={['Progettazione']}
                            autoHighlight
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => setAttivita(value)}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Scegli l'attivià dall'elenco"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>

                        <TextField
                            label="Ore"
                            id="ore-input"
                            type="number"
                            sx={{ width: '25ch' }}
                            onChange={(event) => setOre(event.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">h</InputAdornment>,
                            }}
                        />

                    </Grid>

                    <Grid item xs={12}>

                        <Button variant={"contained"} color={"primary"} sx={{mt: 5, px: 5}}
                                onClick={() => setOpen(true)}
                                endIcon={<Save />} disabled={!commessa || !attivita || !ore}
                                style={{height: '3rem', fontSize: '1.2rem'}}>SALVA</Button>

                    </Grid>
                </Grid>
            </Container>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert elevation={6} onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Ore memorizzate con successo
                </Alert>
            </Snackbar>
        </main>
    )

}

export default OreCommessa;
