import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import logo from '../assets/cerrato_logo.png';

export default function MenuAppBar({user}) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <img src={logo} style={{width: '10rem', marginRight: '2rem'}} />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Servizi
                    </Typography>
                    <div>
                        <Typography variant="p" component="span">
                            {user?.cognome?.toUpperCase()}
                        </Typography>
                        <IconButton
                            style={{marginBottom: 3}}
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
