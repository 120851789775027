import {Box, Button, Card, CardActions, CardContent, Container, Grid, Typography} from "@mui/material";
import MenuAppBar from "../components/MenuAppBar";
import {Link} from "react-router-dom";
import {useAuth} from "../services/AuthContext";

const Services = () => {

    const {currentUser} = useAuth();

    return (
        <main>
            <MenuAppBar user={currentUser}/>
            <Container>
                <Grid container spacing={2} sx={{mt: 4}}>
                    <Card sx={{ minWidth: 275 }} component={Link} to={'/ore-commessa'}  style={{textDecoration: 'none'}}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Gestione Produzione
                            </Typography>
                            <Typography variant="h5" component="div">
                                Inserisci Ore<br/>
                                Commessa
                            </Typography>
                            <Typography sx={{ fontSize: 14, mt: 3 }} color="text.secondary" gutterBottom>
                                Scegli l'attività e indica le ore spese
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Inserisci Ora</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Container>
        </main>
    )

}

export default Services;
